import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PastrareaBuchetuluiPage = () => (
  <Layout>
    <SEO title="Cum păstrăm buchetul de mireasă?" />
    <div className="drawer article-content">
      <h1 className="article-title">Cum păstrăm buchetul de mireasă?</h1>
      <p>
        După ce ți-ai creat buchetul mult visat și te-ai bucurat toată ziua de
        aprecieri, cu siguranță nu vei vrea să renunți la el, doar în cazul în
        care păstrzi obiceiul cu aruncarea buchetului și îl dăruiești fetei care
        l-a prins. 
      </p>
      <section>
        <h3 className="paragh-title">Cum poți păstra buchetul? </h3> <br/>
        <p>
        ①  Îl pui într-o vază, fără apă și îl
          păstrezi direct așa. Iei o vază mai mare decât buchetul și îl bagi cu
          totul acolo sau îl bagi sub un clopot de sticlă, ca acelea în care se
          păstrează trandafirii criogenați. 
        </p>
        <p>
        ②  Îl păstrezi într-un tablou, sigur puțin customizat, cu rame mai înalte. 
        </p>
        <p>
        ③ Îl poți păstra într-o cutie transparentă. 
        </p>
        <p>
        ④ Poți rupe flori din buchet și le poți păstra individual,fie flori întregi, fie petalele. 
        </p>
      </section>
      <section>
        <p>
          Indiferent cum vei decide să îl păstrezi, cu siguranță te vei bucura de el și îți va aduce mereu aminte
          de ziua voastră specială.
        </p>
      </section>
      <div className="nav-section">
        <Link to="/buchetul">◀ Buchetul de mireasă </Link>
        <Link to="/rochia">▶ Rochia de mireasă</Link>
      </div>
    </div>
  </Layout>
)

export default PastrareaBuchetuluiPage
